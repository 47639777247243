import {AuthRoute} from "@chaosoft/admin";
import {useStores} from "./stores/hooks";
import {observer} from 'mobx-react';

const LoginRoute = observer((props) => {
  const {userStore} = useStores();
  return (
    <AuthRoute {...props} access={userStore.isLogin} redirect="/login">
      {props.children}
    </AuthRoute>
  )
})

const routes = [{
  path: '/',
  redirect: '/home',
  exact: true
}, {
  path: '/home',
  component: './pages/home/home.js',
  exact: true
}, {
  path: '/login',
  component: './pages/login/login.js',
  exact: true
}, {
  path: '/bind',
  component: './pages/login/bind.js',
  exact: true
}, {
  path: '/report/:key',
  component: './pages/prediction/report.js',
  exact: true
}, {
  path: '/account',
  component: './pages/account/account.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/vip',
  component: './pages/product/vip.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/pay/:order',
  component: './pages/order/pay.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/orders',
  component: './pages/order/list.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/orders/:order',
  component: './pages/order/order.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/transition',
  component: './pages/prediction/transition.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/promotion',
  component: './pages/promotion/promotion.js',
  exact: true
}, {
  path: '/investor/list',
  component: './pages/investor/list.js',
  exact: true
}, {
  path: '/investor/detail',
  component: './pages/investor/detail.js',
  exact: true
}, {
  path: '/home/optimizes',
  component: './pages/home/daily-stock.js',
  exact: true
}, {
  path: '/home/usage',
  component: './pages/home/popular.js',
  exact: true
}, {
  path: '/fund',
  component: './pages/fund/fund.js',
  exact: true
}, {
  path: '/fund/report/:key',
  component: './pages/fund/report.js',
  exact: true
}];

export default routes;
