import React from "react";
import {observer} from 'mobx-react';
import styles from "./guide-app.scss";
import {Image, Mask} from "antd-mobile";
import {useStores} from "../../stores/hooks";

const GuideApp = () => {

  const {weappStore} = useStores();

  const handleClick = () => {
    if (weappStore.guide === 'step_1') {
      weappStore.setGuide("step_2")
    } else {
      weappStore.setGuide(null);
    }
  }

  return (
    <Mask visible={!!weappStore.guide} style={{'--z-index': 10000}}>
      {weappStore.guide && (
        <Image
          className={styles.guideImage}
          fit='scale-down' onClick={handleClick}
          src={require(`../../assets/home/guide_${weappStore.guide}.png`)}
        />
      )}
    </Mask>
  )

}

export default observer(GuideApp);