// 配置url前缀 不可变动
export const BASE_NAME = process.env.PUBLIC_URL;
export const API_PATH = process.env.REACT_APP_API_PATH;
export const TIME_PATH = process.env.REACT_APP_TIME_PATH;
export const FILE_PATH = process.env.REACT_APP_FILE_PATH;
export const TRANSITION_DURATION = 5;
export const CRYPTO_KEY = '8626319691cc47beb490a9018ac5d508';
export const WECHAT_APPID = 'wx558012aee5800e90'
export const WEAPP_APPID = 'wx2a8b9bbd429ab9c5';
export const STATIC_URI = 'https://cbgpt-static.oss-cn-shanghai.aliyuncs.com/mini-images/v1.x';

export const GOOGLE_CLIENT_ID = '920802528914-n63uddl0fjstr1fqksf64fd46o1oao7e.apps.googleusercontent.com';