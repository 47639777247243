import request from "../utils/request";
import qs from "query-string";

export const postPayment = async (code, mode, query) => {
  const url = qs.stringifyUrl({
    url: '/payment', query
  }, {skipEmptyString: true, skipNull: true});
  return request.post(url, {requestType: 'form', data: {code, mode}})
}

export const loadPayment = async (code) => {
  return request.get(`/payment/${code}`)
}