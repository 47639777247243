import {action, computed, observable, runInAction} from "mobx";
import {LocalStore} from "./local.store";
import {FinanceApi, LoginApi, UserApi, WechatApi} from "../api";
import qs from "query-string";
import {BusiError} from "../utils/common";
import dayjs from "dayjs";

class UserStore extends LocalStore {

  @observable userInfo = {}
  @observable queryToken
  @observable targets = []
  @observable wechat = {}
  @observable invite

  get storeName() {
    return "user_data"
  }

  @computed
  get loginInfo() {
    return this.datas.login || {};
  }

  @computed
  get loginInvite() {
    return this.invite && btoa(JSON.stringify(this.invite))
  }

  @computed
  get weappInvite() {
    if (this.invite) {
      const {inviteSource: source, inviteCode: invite} = this.invite;
      return {scene: qs.stringify({source, invite})}
    }
    return null;
  }

  @computed
  get originInvite() {
    if (this.invite) {
      const {inviteSource: source, inviteCode: invite} = this.invite;
      return {source, invite}
    }
    return {};
  }

  @computed
  get userkey() {
    const loginkey = this.loginInfo.userKey
    return loginkey && loginkey.split(':')[1];
  }

  @computed
  get token() {
    return this.loginInfo?.token
  }

  @computed
  get isLogin() {
    return !!this.token;
  }

  @computed
  get isVirtual() {
    const {userKey} = this.loginInfo;
    return userKey === 'virtual'
  }

  @computed
  get isMember() {
    const {memberExpiredTime} = this.userInfo;
    if (memberExpiredTime) {
      const expire = dayjs(memberExpiredTime);
      const current = dayjs().startOf('d');
      return expire.isAfter(current)
    }
    return false;
  }

  @computed
  get hasTicket() {
    const {ticketCount} = this.userInfo;
    return ticketCount && ticketCount > 0;
  }

  constructor() {
    super();
    // this.resetLoginInfo();
  }

  resetLoginInfo = async () => {
    const {token: queryToken} = qs.parse(location.search);
    const {token: storeToken} = this.loginInfo;
    const token = queryToken || storeToken;
    if (token) {
      const loginInfo = await LoginApi.getLoginInfo(token);
      this.setItem("login", loginInfo)
      await this.refresh();
    }
  }

  loginVerifyCode = async (datas, certType = 'PHONE') => {
    const loginInfo = await LoginApi.postLogin({
      certType,
      tokenType: 'LONG_TIME',
      content: {
        ...datas,
        userType: 'USER',
        verifyType: 'VERIFY_CODE'
      }
    }, this.loginInvite)
    this.setItem("login", loginInfo)
    await this.refresh();
  }

  loginGoogle = async (credential) => {
    const loginInfo = await LoginApi.postLogin({
      certType: 'GOOGLE',
      tokenType: 'LONG_TIME',
      content: {
        userType: 'USER',
        credential,
      }
    }, this.loginInvite)
    this.setItem("login", loginInfo)
    await this.refresh();
  }

  @action
  refresh = async () => {
    if (this.isLogin && !this.isVirtual) {
      const [userinfo, targets, wechat] = await Promise.all([
        UserApi.getCurrent(),
        FinanceApi.findTarget({limit: 7}),
        WechatApi.getWechatInfo()
      ]);
      runInAction(() => {
        this.userInfo = {
          ...(userinfo || {}),
        };
        this.targets = targets || []
        this.wechat = wechat || {}
      })
    }
  }

  @action
  logout = async () => {
    try {
      await LoginApi.deleteLogin();
    } catch (e) {
    } finally {
      this.removeItem('login')
    }
  }

  @action
  bindLoginPhone = async ({phone, code}) => {
    if (this.loginInfo.loginType !== 'WECHAT') {
      throw new BusiError('当前登录类型必须为微信登录方可绑定登录电话')
    }
    await LoginApi.putLoginPhone({phone, code}, this.loginInvite);
    await this.resetLoginInfo();
  }

  @action
  setChannel = (channel) => {
    this.channel = channel;
  }

  @action
  setInvite = (inviteSource, inviteCode) => {
    if (inviteSource || inviteCode) {
      this.invite = {
        inviteSource, inviteCode
      }
    } else {
      this.invite = null;
    }
  }

}

export default new UserStore()