import request from "../utils/request";
import {userStore} from '../stores'
import {WECHAT_APPID} from "../utils/constants";

export const getWechatInfo = async () => {
  if (!userStore.isLogin) {
    throw new BusiError('尚未登录')
  }
  if (userStore.isVirtual) {
    return []
  }
  return request.get(`/user/${userStore.userkey}/wechat`)
}

export const postJsapiSign = async () => {
  const data = {url: window.location.href, appid: WECHAT_APPID}
  return request.post('/wechat/jsapi-sign', {requestType: 'form', data})
}