import {action, observable} from "mobx";
import {isUnsupportScheme, toast} from "../utils/common";
import {WEAPP_APPID} from "../utils/constants";
import deviceStore from "./device.store";
import userStore from "./user.store";
import qs from "query-string";

export class WeappStore {

  @observable visible = true;
  @observable guide = null;

  @action
  setGuide = (guide) => {
    this.guide = guide;
  }

  @action
  setVisible = (visible) => {
    this.visible = visible;
  }

  openWeapp = (params) => {
    if (isUnsupportScheme()) {
      this.setGuide('step_1')
      return;
    }
    if (!deviceStore.isMobile) {
      toast('请使用手机浏览器打开。')
      return
    }
    const query = {...(userStore.weappInvite || {}), token: userStore.token}
    let openlink = `weixin://dl/business/?appid=${WEAPP_APPID}&path=pages/home/home&query=${encodeURIComponent(qs.stringify(query))}`;
    if (params?.report) {
      query.key = params.report;
      openlink = `weixin://dl/business/?appid=${WEAPP_APPID}&path=pages/prediction/report&query=${encodeURIComponent(qs.stringify(query))}`;
    }
    window.location.href = openlink
  }

}

export default new WeappStore()