import {action, computed, observable, runInAction} from "mobx";

export class LoadMoreStore {

  @observable list = []
  @observable page = {}
  @observable loading = false
  params = {}
  size = 20
  skeleton = false

  @computed get hasMore() {
    return !this.page.last;
  }

  constructor({size, fetch, skeleton} = {}) {
    this.skeleton = skeleton
    this.size = size || 20;
    this.fetch = fetch;
    if (!fetch || typeof fetch !== 'function') {
      throw new TypeError("create load more store, fetch require function")
    }
  }

  @action
  loadData = async (query, first) => {
    try {
      this.loading = true
      if (this.skeleton) {
        const skeletons = Array.from({length: this.size}).map(() => ({skeleton: true}))
        this.list = [...this.list, ...skeletons]
      }
      const {content, ...page} = await Promise.resolve(this.fetch(query));
      runInAction(() => {
        if (!first && this.skeleton) {
          this.list = this.list.filter(item => !item.skeleton)
        }
        this.list = first ? content : [...this.list, ...content]
        this.page = page
      })
    } catch (e) {
      this.list = this.list.filter(item => !item.skeleton)
      throw e;
    } finally {
      this.loading = false
    }
  }

  @action
  refresh = async (params) => {
    await this.loadData({
      ...(params || {}),
      ...{page: 0, size: params?.size || this.size},
    }, true)
    this.params = params || {}
  }

  loadMore = async () => {
    if (this.hasMore) {
      await this.loadData({
        ...(this.params || {}),
        ...{page: this.page.current + 1, size: this.params.size || this.size},
      })
    }
  }

  @action
  setList = (list) => {
    this.list = list
  }
}