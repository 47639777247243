import request from "../utils/request";
import qs from "query-string";

export const findTargets = (query) => {
  return request.get(qs.stringifyUrl({
    url: '/targets', query
  }, {skipNull: true, skipEmptyString: true}))
}

export const loadStock = async (code) => {
  return request.get(`/targets/STOCK/${code}`)
}


export const loadInvestor = async (code) => {
  return request.get(`/targets/INVESTOR/${code}`)
}

export const loadHoldings = async (key) => {
  return request.get(`/targets/${key}/holdings`)
}

export const loadActivitys = async (key, period = 'last') => {
  return request.get(qs.stringifyUrl({
    url: `/targets/${key}/activitys`, query: {period}
  }, {skipEmptyString: true, skipNull: true}))
}

export const findTargetHoldings = async (query) => {
  return request.get(qs.stringifyUrl({
    url: '/targets/holdings', query
  }, {skipEmptyString: true, skipNull: true}))
}

