import request from "../utils/request";
import qs from "query-string";

export const postLogin = async (data, invite) => {
  return request.post(qs.stringifyUrl({
    url: '/login',
    query: {invite}
  }, {skipNull: true, skipEmptyString: true}), {data})
}

export const deleteLogin = async () => {
  return request.delete('/login')
}

export const getLoginInfo = async (token) => {
  return request.get(qs.stringifyUrl({
    url: '/login', query: {token}
  }, {skipNull: true, skipEmptyString: true}))
}

export const putLoginPhone = async (data, scene) => {
  return request.put(qs.stringifyUrl({
    url: '/login/phone', query: {scene}
  }, {skipNull: true, skipEmptyString: true}), {data})
}

export const sendVerifyCode = async (target, type) => {
  return request.post('/login/verify-code', {data: {target, type}, requestType: "form"})
}