import request from "../utils/request";

export const postVisit = async (code) => {
  return request.post(`/toolkit/visit/${code}`)
}

export const translation = async (text, target) => {
  return await request.post('/toolkit/translate', {
    requestType: 'text', data: text, headers: {'Accept-Language': target}
  })
}