import request from "../utils/request";
import {userStore} from '../stores'
import qs from "query-string";

//创建报告
export const postReport = async (code) => {
  return request.post('/finance/report', {requestType: 'form', data: {code}})
}

//查看报告
export const loadReport = async (key) => {
  return request.get(`/finance/report/${key}?showUnpaid=true`)
}

//购买报告
export const putReport = async (key) => {
  return request.put(`/finance/report/${key}`)
}

export const findTarget = async (query) => {
  if (!userStore.isLogin) {
    throw new BusiError('尚未登录')
  }
  if (userStore.isVirtual) {
    return []
  }
  return request.get(qs.stringifyUrl({
    url: `/user/${userStore.userkey}/targets`, query
  }, {skipEmptyString: true, skipNull: true}))
}

export const findReports = async (query) => {
  if (!userStore.isLogin) {
    throw new BusiError('尚未登录')
  }
  if (userStore.isVirtual) {
    return []
  }
  return request.get(qs.stringifyUrl({
    url: `/user/${userStore.userkey}/reports`, query
  }, {skipEmptyString: true, skipNull: true}))
}

export const getRecommendReports = async () => {
  return request.get('/finance/report/recommend')
}

export const getOptimizeReports = async () => {
  return request.get('/finance/report/optimize')
}

export const getStockUsage = async (query) => {
  return request.get(qs.stringifyUrl({
    url: '/finance/stock-usage', query
  }, {skipEmptyString: true, skipNull: true}))
}

/**
 * 提交反馈
 * @param reportkey 报告ID
 * @param data 反馈内容 ｛"level": "LEVEL_1", "suggest": "xxx"｝ level: LEVEL_[1-5]
 * @returns {Promise<void>}
 */
export const postFeedback = async (reportkey, data) => {
  return request.post(`/finance/report/${reportkey}/feedback`, {data})
}

/**
 *
 * @param reportkey 报告ID
 * @param simple 如果为true，则只返回反馈内容，否则会携带报告和用户数据一起返回
 * @returns {Promise<any>}
 */
export const getFeedback = async (reportkey, simple = true) => {
  return request.get(qs.stringifyUrl({
    url: `/finance/report/${reportkey}/feedback`, query: {simple}
  }, {skipEmptyString: true, skipNull: true}))
}