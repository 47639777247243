import zhCN from 'antd-mobile/es/locales/zh-CN';

export default {
  label: '简体中文',
  ...zhCN,
  "home": {
    "search_bar_title": "获取报告",
    "search_bar_placeholder": "请输入股票代码或者简称",
    "hot_title": "热门报告",
    "daily_stock_title": "每日金股",
    "daily_stock_desc": "StockGPT金融大模型推荐",
    "popular_title": "美股人气榜",
    "popular_desc": "近1个月预测分析报告",
    "holder_title": "大神持仓追踪",
    "visit_times": '次',
    "up": '看涨',
    "down": '看跌',
  },
  "daily": {
    "stock_title": "每日金股",
    "stock_update_time": "更新",
    "up": '看涨',
    "down": '看跌',
    "predict_text": '市场观点',
    "stock_declare": "*声明：诊断数据来源于市场公开信息，经大数据抓取、深度挖掘及AI模型运算整合后得出。所提供信息不构成投资建议，亦不代表公司观点。 投资有风险，入市需谨慎。"
  },
  "popular": {
    "stock_title": "美股人气榜",
    "stock_update_time": "更新",
    "visit_times": '次',
    "item_desc": '近1个月预测次数'
  },
  "account": {
    "title": "个人中心",
    "member_expired_time": "会员2024.07.18到期",
    "history": "历史记录",
    "up": '看涨',
    "down": '看跌',
    "analyse_report": "分析报告",
    "no_data": "暂无历史报告",
  },
  "report": {
    "title": "分析报告",
    "time_zone": "美东",
    "comment_btn": "去评价",
    "comment_title": "评价一下",
    "up": '看涨',
    "down": '看跌',
    "comment_desc": "您对这次的StockGPT股票预测分析报告体验还满意吗？",
    "comment_level_2": "很满意",
    "comment_level_3": "较差",
    "comment_level_4": "一般",
    "comment_level_5": "还可以",
    "comment_level_6": "很棒",
    "close_price": "收盘价",
    "week_extend": "近一周涨跌幅",
    "company": "公司官网",
    "tab_1": "舆情分析",
    "tab_2": "预测分析",
    "tab_3": "技术分析",
    "tab_4": "大神持仓",
    "summary": "`资讯与财报数据截止到${date}，共${count}条资讯`",
    "adjective": "正面因素",
    "negative": "负面因素",
    "predict_analyse": "预测分析",
    "predict_date": "预测日",
    "predicting": "预测中",
    "result_day": "结果日",
    "predict_week": "预测未来一周",
    "technique_analyse": "技术分析",
    "k_line": "基于近3个月的日K线",
    "holder": "大神投资者",
    "look_all": "查看全部",
    "holder_update_time": "更新时间",
    "holder_count": "大神数量",
    "all_holders_percent": "占所有大神总持仓的比例",
    "hold_price": "持有价格",
    "active_type_all": "全部",
    "active_type_add": "增仓",
    "active_type_reduce": "减仓",
    "active_type_buy": "建仓",
    "active_type_sell": "清仓",
    "holder_list_title_1": "股东名称",
    "holder_list_title_2": "持仓占比",
    "holder_list_title_3": "较上期变化",
    "holder_list_title_4": "持有股数",
    "holder_list_title_5": "持股市值($)",
    "holder_currentValues_format_unit": "万",
    "indicator_analysis_fields_price": "价格走势",
    "indicator_analysis_fields_volume": "成交量",
    "indicator_analysis_fields_relationship": "价量关系",
    "indicator_analysis_fields_indicator": "技术指标",
    "indicator_analysis_fields_trend": "总体趋势及预测",
    "indicator_analysis_fields_summary": "投资建议总结",
    "holder_holdPrice_desc_1": "*“持有价格”是投资者仍持有证券的最后已知价格。它是证券截至投资组合日期（季度末）的收盘价。仅当证券由至少两名投资者拥有时才会提供此值。如果报告日期不同，则计算加权平均价格。",
    "holder_holdPrice_desc_2": "介绍：\n                SEC 要求持有价值 1 亿美元或以上证券的机构基金经理在每个季度末提交 13F 表格披露报告。 尽管管理人员可以对某些职位寻求保密处理，但一般规则是职位必须在季度结束后\n                45 天内报告。",
    "news_type": "专栏",
    "news_desc": "过去一周重要资讯",
    "news_no_data": '暂无筛选结果',
    "news_list_more": "加载更多",
    "news_list_no_more": "已经没有更多了",
    "news_declare": "*声明：诊断数据来源于市场公开信息，经大数据抓取、深度挖掘及AI模型运算整合后得出。所提供信息不构成投资建议，亦不代表公司观点。 投资有风险，入市需谨慎。"
  },
  "transition": {
    "task_1": "加载数据",
    "task_2": "导入资讯",
    "task_3": "量化计算",
    "task_4": "舆情分析",
    "task_5": "多维预测",
    "task_6": "报告生成",
    "title": "分析预测",
    "AI_desc": "StockGPT AI模型运行中",
    "done": "的分析报告已完成",
    "look_report": "立即查看",
    "report_done": "诊断完成",
  },
  "investor": {
    "list_title": "超级投资者",
    "detail_title": "机构详情",
    "detail_summary": "概括",
    "detail_update_time": "更新时间",
    "detail_during": "期间",
    "detail_invest_time": "投资组合日期",
    "detail_stock_count": "股票数量",
    "detail_invest_value": "投资组合价值",
    "detail_hold_list": "持股列表",
    "detail_active_type_all": "全部",
    "detail_active_type_add": "增仓",
    "detail_active_type_reduce": "减仓",
    "detail_active_type_buy": "建仓",
    "detail_active_type_sell": "清仓",
    "detail_holder_list_title_1": "股东名称",
    "detail_holder_list_title_2": "持仓占比",
    "detail_holder_list_title_3": "较上期变化",
    "detail_holder_list_title_4": "持有股数",
    "detail_holder_list_title_5": "持股市值($)",
    "detail_currentValues_format_unit": "万"
  },
  "login": {
    "title": "免费注册或登入",
    "placeholder_phone": "输入手机号",
    "placeholder_email": "请输入邮箱",
    "verify_btn": "获取验证码",
    "or": "或者",
    "guide": "使用协议",
    "privacy": "隐私协议",
    "login_fail": "登入失败",
    "login": "登入",
    "register": "注册",
    "verify_phone": "验证码已发送至",
    "verify_email": "验证码已发送至",
    "re_send": "重新发送",
    "google_error": "谷歌授权出错"
  },
  "bind": {
    "title": "登入成功，请绑定手机号",
    "description": "绑定后下次可直接使用手机号登入",
    "verify_btn": "获取验证码",
    "modal_title": "请确认是否绑定",
    "text1": " 此手机号已注册<span>StockGPT</span>账号，是否将该微信号与",
    "text2": "手机号绑定？",
    "confirm": '确认绑定',
    "change_phone": "换个手机号",
    "bind_success": "绑定成功",
    "confirm_txt": "已了解",
    "contact_text": "您可使用此手机号登录当前<span>StockGPT</span>账号",
    "bind_fail": "电话绑定失败",
    "verify_title": "请输入验证码",
    "done": "完成",
    "verify_phone": "验证码已发送至+86",
    "re_send": "重新发送",
    "input_phone": "输入手机号"
  }
}