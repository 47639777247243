import request from "../utils/request";
import qs from "query-string";

export const findProducts = async (query) => {
  return request.get(qs.stringifyUrl({
    url: '/products', query
  }, {skipNull: true, skipEmptyString: true}))
}

export const postProduct = async (type) => {
  return request.post(`/products`, {requestType: 'form', data: {type}})
}

export const putProduct = async (key, data) => {
  return request.put(`/products/${key}`, {data})
}

export const putDisable = async (key, disable) => {
  return request.put(`/products/${key}/disable`, {requestType: 'form', data: {disable}})
}

export const findOrders = async (key, query) => {
  return request.get(qs.stringifyUrl({
    url: `/products/${key}/orders`, query
  }, {skipNull: true, skipEmptyString: true}))
}