import enUS from 'antd-mobile/es/locales/en-US';

export default {
  ...enUS,
  label: 'English',
  "home": {
    "search_bar_title": "Get Report",
    "search_bar_placeholder": "Please enter stock code",
    "hot_title": "Popular Reports",
    "daily_stock_title": "Daily Top Picks",
    "daily_stock_desc": "StockGPT Financial Model Recommendation",
    "popular_title": "Popularity Ranking",
    "popular_desc": "Reports Generated in the Past Month",
    "holder_title": "Investors' Holdings Tracking",
    "visit_times": 'times',
    "up": 'Long',
    "down": 'Short',
  },
  "daily": {
    "stock_title": "Daily Top Picks",
    "stock_update_time": "Update",
    "predict_text": 'market sentiment',
    "up": 'Long',
    "down": 'Short',
    "stock_declare": "*Disclaimer: The diagnostic data is derived from public market information, obtained through big data collection, in-depth mining, and AI model computation. The provided information does not constitute investment advice, nor does it represent the views of the company. Investing involves risks, please be cautious.\n\n\n\n\n\n"
  },
  "popular": {
    "stock_title": "Popularity Ranking",
    "stock_update_time": "Update",
    "visit_times": 'times',
    "item_desc": 'forecast frequency in the past month'
  },
  "account": {
    "title": "Personal Center",
    "member_expired_time": "Membership Expires on 2024.07.18",
    "history": "History",
    "up": 'Long',
    "down": 'Short',
    "analyse_report": "Analysis Report",
    "no_data": "No historical reports",
  },
  "report": {
    "title": "Analysis Report",
    "time_zone": "EST",
    "comment_btn": "Review",
    "up": 'Long',
    "down": 'Short',
    "comment_title": "Leave a Review",
    "comment_desc": "Are you satisfied with this StockGPT stock prediction analysis report?",
    "comment_level_2": "Very Satisfied",
    "comment_level_3": "Poor",
    "comment_level_4": "Average",
    "comment_level_5": "Okay",
    "comment_level_6": "Great",
    "close_price": "Closing Price",
    "week_extend": "Weekly Change",
    "company": "Official Website",
    "tab_1": "Sentiment",
    "tab_2": "Prediction",
    "tab_3": "Technical",
    "tab_4": "Top Institutions",
    "predict_date": "Forecast Day",
    "summary": "`Information and financial report data as of ${date}, a total of ${count} pieces of information`",
    "adjective": "Positive factors",
    "negative": "Negative factors",
    "predict_analyse": "Forecast analysis",
    "predicting": "Verifying",
    "result_day": "Result date",
    "predict_week": "Forecast for the next week",
    "technique_analyse": "Technical analysis",
    "k_line": "Based on the daily K-line of the past 3 months",
    "holder": "Top investors",
    "look_all": "View all",
    "holder_update_time": "Update time",
    "holder_count": "Ownership count",
    "all_holders_percent": "% of all portfolios",
    "hold_price": "Holding price",
    "active_type_all": "All",
    "active_type_add": "Increase",
    "active_type_reduce": "Decrease",
    "active_type_buy": "New",
    "active_type_sell": "Sold Out",
    "holder_list_title_1": "Portfolio Manager",
    "holder_list_title_2": "% of portfolio",
    "holder_list_title_3": "Recent activity",
    "holder_list_title_4": "Shares",
    "holder_list_title_5": "Value",
    "holder_currentValues_format_unit": "Ten thousand",
    "indicator_analysis_fields_price": "Price trend",
    "indicator_analysis_fields_volume": "Trading volume",
    "indicator_analysis_fields_relationship": "Price-volume relationship",
    "indicator_analysis_fields_indicator": "Technical indicators",
    "indicator_analysis_fields_trend": "Overall trend and forecast",
    "indicator_analysis_fields_summary": "Investment advice summary",
    "holder_holdPrice_desc_1": "* \"Holding price\" is the last known price at which investors still hold the security. It is the closing price of the security as of the portfolio date (end of the quarter). This value is only provided when the security is owned by at least two investors. If the report dates are different, a weighted average price is calculated.",
    "holder_holdPrice_desc_2": "Introduction:\nThe SEC requires institutional fund managers holding securities worth $100 million or more to submit Form 13F disclosure reports at the end of each quarter. While managers can seek confidential treatment for certain positions, the general rule is that positions must be reported within 45 days of the quarter's end.",
    "news_type": "Column",
    "news_desc": "Important informations",
    "news_no_data": 'No filter results',
    "news_list_more": "Load more",
    "news_list_no_more": "No more",
    "news_declare": "*Disclaimer: Diagnostic data is sourced from publicly available market information, extracted, deeply mined, and integrated through AI model calculations. The provided information does not constitute investment advice and does not represent the company's views. Investing is risky, proceed with caution."
  },
  "transition": {
    "task_1": "Loading",
    "task_2": "Import information",
    "task_3": "Quantitative calculation",
    "task_4": "Sentiment analysis",
    "task_5": "Multidimensional forecast",
    "task_6": "Report generation",
    "title": "Analysis and forecast",
    "AI_desc": "StockGPT AI model in operation",
    "done": "analysis report is complete",
    "look_report": "View now",
    "report_done": "Diagnosis completed",
  },
  "investor": {
    "list_title": "Top Investors",
    "detail_title": "Institution details",
    "detail_summary": "Summary",
    "detail_update_time": "Update time",
    "detail_during": "Period",
    "detail_invest_time": "Portfolio date",
    "detail_stock_count": "No. of stocks",
    "detail_invest_value": "Portfolio value",
    "detail_hold_list": "Holding List",
    "detail_active_type_all": "All",
    "detail_active_type_add": "Increase",
    "detail_active_type_reduce": "Decrease",
    "detail_active_type_buy": "New",
    "detail_active_type_sell": "Sold Out",
    "detail_holder_list_title_1": "Portfolio Manager",
    "detail_holder_list_title_2": "% of portfolio",
    "detail_holder_list_title_3": "Recent activity",
    "detail_holder_list_title_4": "Shares",
    "detail_holder_list_title_5": "Value",
    "detail_currentValues_format_unit": "Ten thousand"
  },
  "login": {
    "title": "Log in",
    "placeholder": "Enter your phone number",
    "placeholder_phone": "Enter your phone number",
    "placeholder_email": "Enter your email address",
    "verify_btn": "Get verification code",
    "or": "or",
    "guide": "Agreement",
    "privacy": "Privacy Policy",
    "login_fail": "Login failed",
    "login": "Login",
    "register": "Register",
    "verify_phone": "Verification code has been sent to",
    "verify_email": "Verification code has been sent to",
    "re_send": "Resend",
    "google_error": "Google authorization error"
  },
  "bind": {
    "title": "Login successful, please bind your phone number",
    "description": "After binding, you can log in directly with your phone number next time",
    "verify_btn": "Obtain verification code",
    "modal_title": "Please confirm if it is bound",
    "text1": "This phone number has already registered a <span>StockGPT</span> account. Do you want to link this WeChat account with",
    "text2": "Phone number binding?",
    "confirm": "Confirm binding",
    "change_phone": "Change your phone number",
    "bind_success": "Binding successful",
    "confirm_txt": "Understood",
    "contact_text": "You can use this phone number to log in to your current <span>StockGPT</span> account",
    "bind_fail": "Phone binding failed",
    "verify_title": "Please enter the verification code",
    "done": "Done",
    "verify_phone": "The verification code has been sent to+86",
    "re_send": "Resend",
    "input_phone": "Enter phone number"
  }
}