import React, {useEffect} from "react";
import Loadable from 'react-loadable';
import * as stores from './stores'
import {observer, Provider} from "mobx-react";
import WechatShare from './components/wechat';
import {BrowserRouter, Redirect, Route as ReactRoute, Switch} from "react-router-dom";
import routes from "./routes";
import './app.scss';
import {ToolkitApi, WechatApi} from "./api";
import {GOOGLE_CLIENT_ID, WECHAT_APPID} from "./utils/constants";
import {isWechat} from "./utils/common";
import qs from "query-string";
import GuideApp from "./components/notice/guide-app";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {ConfigProvider} from "antd-mobile";


const ObserverConfigProvider = observer(({children, ...restProps}) => {

  const {langStore} = stores;

  return (
    <ConfigProvider {...restProps} locale={langStore.language}>
      {children}
    </ConfigProvider>
  )
})

const App = () => {

  const {source, invite, guide, stock} = qs.parse(location.search)
  const {userStore, deviceStore, weappStore} = stores;

  useEffect(() => {
    if (source) {
      const code = [source, stock].filter(item => !!item).join('_')
      ToolkitApi.postVisit(code.toLowerCase())
    }
    userStore.setInvite(source, invite)
    userStore.refresh()
    deviceStore.load()
    weappStore.setGuide(guide)
  }, [])

  const importRouteComponent = (routes) => {
    return routes.map(item => ({
      ...item,
      component: item.component && (typeof item.component === 'string' ? Loadable({
        loader: () => import(`${item.component}`),
        loading: () => null,
      }) : item.component),
      routes: item.routes && importRouteComponent(item.routes)
    }))
  }

  const createLazyRoute = (routes) => {
    return routes && (
      <Switch>
        {routes.map(({path, Route = ReactRoute, component: Component, redirect, routes: childs, ...restProps}, index) => {
          if (redirect) {
            return <Redirect key={index} {...restProps} from={path} to={redirect}/>
          }
          return (
            <Route key={index} path={path} {...restProps} routes={childs} render={(props) => {
              if (Component) {
                return (
                  <Component {...props} routes={childs}>
                    {childs && childs.length > 0 && createLazyRoute(childs)}
                  </Component>
                )
              }
              return childs && childs.length > 0 && createLazyRoute(childs);
            }}/>
          )
        })}
      </Switch>
    )
  }
  return (
    <Provider {...stores}>
      <ObserverConfigProvider>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            {isWechat() && (
              <WechatShare
                title="StockGPT"
                desc="首款股票预测金融大模型"
                imgUrl={`https://${window.location.host}/logo.png`}
                appid={WECHAT_APPID}
                sign={() => WechatApi.postJsapiSign()}
              />
            )}
            <GuideApp/>
            <div className="app">
              {createLazyRoute(importRouteComponent(routes))}
            </div>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </ObserverConfigProvider>
    </Provider>
  )
}

export default App;
