import {action, computed, observable} from "mobx";
import * as locales from '../locales'
import {setDefaultConfig} from "antd-mobile";
import {ExtendApi, ToolkitApi} from "../api";

const CACHE = {}

export class LangStore {

  @observable locale = 'zhCN'
  @observable dict = {}
  @observable _stocknames = {}

  @computed get language() {
    return locales[this.locale] || {};
  }

  @computed get stockNames() {
    return this._stocknames[this.locale] || {}
  }

  @computed get standard() {
    switch (this.locale) {
      case "zhCN":
        return 'zh-CN';
      case 'zhTW':
        return 'zh-TW';
      default:
        return 'en-US'
    }
  }

  constructor() {
    switch (navigator.language) {
      case 'zh':
      case 'zh-CN':
        this.setLanguage('zhCN');
        break;
      case 'zh-TW':
        this.setLanguage('zhTW');
        break;
      default:
        this.setLanguage('enUS');
        break;
    }
    this.loadStockNames();
  }

  @action
  loadStockNames = async () => {
    const enUS = await ExtendApi.loadStaticFile("/stock_names_en.js");
    this._stocknames = {...this._stocknames, enUS}
  }

  @action
  setLanguage = (name) => {
    CACHE[this.locale] = {...this.dict}
    this.locale = name;
    setDefaultConfig({locale: this.language})
    this.dict = {...(CACHE[name] || {})}
  }

  @action
  translate = async (key, value) => {
    if (!this.dict[key]) {
      let text = value;
      if (this.locale !== 'zhCN') {
        text = await ToolkitApi.translation(value, this.standard);
      }
      this.dict = {...this.dict, [key]: text}
    }
  }

  @action
  translateStockName = async (code, name) => {
    if (this.stockNames[code]) {
      return
    }
    if (!this.locale.startsWith("en")) {
      let text = name;
      if (this.locale !== 'zhCN') {
        text = await ToolkitApi.translation(text, this.standard);
      }
      const names = this.stockNames;
      this._stocknames = {...this._stocknames, [this.locale]: {...names, [code]: text}}
    }
  }


}

export default new LangStore();