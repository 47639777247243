import React from "react";
import {MobXProviderContext, useLocalStore} from "mobx-react";
import {LoadMoreStore} from "./load-more.store";
import {LoadPageStore} from "./load-page.store";

export const useStores = () => React.useContext(MobXProviderContext);

export const useLoadMore = (option) => {
  const store = useLocalStore(() => new LoadMoreStore(option))
  return [store.list, store.hasMore, store.refresh, store.loadMore, {
    loading: store.loading,
    params: store.params,
    setList: store.setList
  }]
}

export const useLoadPage = (option) => {
  const store = useLocalStore(() => new LoadPageStore(option));
  return [store.list, store.page, store.loadData, {loading: store.loading, params: store.params}]
}