import {action, computed, observable} from "mobx";
import {ExtendApi} from "../api";
import {isMobile} from 'react-device-detect';
import {isWechat} from "../utils/common";

class DeviceStore {

  @observable clientIp;
  @observable isMobile;
  @computed get wxpayMode () {
    if (!this.isMobile) {
      return "WECHAT_NATIVE";
    }
    if (isWechat()) {
      return "WECHAT_JSAPI"
    }
    return "WECHAT_H5"
  }

  @action
  load = async () => {
    const response = await ExtendApi.loadLocalAddress();
    this.clientIp = response.ip;
    this.isMobile = isMobile;
  }

}

export default new DeviceStore();