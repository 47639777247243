import {action, computed, observable} from "mobx";
import {LocalStore} from "./local.store";
import {ExtendApi} from "../api";

class FundStore extends LocalStore {

  @observable data = []


  get storeName() {
    return "user_data"
  }

  @computed
  get fundData() {
    return this.data;
  }

  @action
  loadFundPredict = async () => {
    const datas = await ExtendApi.loadStaticFile("/agent/fund_predict.js", {name: "cb1"});
    this.data = (datas || []).sort((a, b) => a.fundCode.localeCompare(b.fundCode))
  }
}

export default new FundStore()