import {useEffect} from "react";
import {useHistory} from "react-router-dom";

const WechatShare = ({appid, sign, title, desc, imgUrl, children}) => {

  const history = useHistory();

  useEffect(() => {
    if (sign && typeof sign === 'function') {
      initial()
      const unlisten = history.listen(initial)
      return () => unlisten()
    }
  }, [sign]);

  const initial = async () => {
    const s = await Promise.resolve(sign())
    if (s) {
      wx.config({
        ...s,
        appId: appid, // 必填，公众号的唯一标识
        nonceStr: s.nonce, // 必填，生成签名的随机串
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      })
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
          success: ({checkResult}) => {
            if (checkResult.updateAppMessageShareData) {
              wx.updateAppMessageShareData({
                title, desc, imgUrl, link: window.location.href,
                success: function () {
                  console.log('分享设置成功')
                }
              })
            }
            if (checkResult.updateTimelineShareData) {
              wx.updateTimelineShareData({
                title, imgUrl, link: window.location.href,
                success: function () {
                  console.log('分享设置成功')
                }
              })
            }
          }
        })
      })
    }
  }
  return children;
}

export default WechatShare;