import {action, observable, runInAction} from "mobx";

export class LoadPageStore {

  @observable list = []
  @observable page = {}
  @observable loading = false
  params = {}
  size = 20
  skeleton = false

  constructor({size, fetch, skeleton} = {}) {
    this.skeleton = skeleton
    this.size = size || 20;
    this.fetch = fetch;
    if (!fetch || typeof fetch !== 'function') {
      throw new TypeError("create load more store, fetch require function")
    }
  }

  @action
  loadData = async (query, force) => {
    try {
      this.loading = true
      const params = {
        page: 0, size: this.size,
        ...(force ? {} : this.params),
        ...(query || {})
      }
      const {content, ...page} = await Promise.resolve(this.fetch(params));
      runInAction(() => {
        this.params = params
        this.list = content
        this.page = page
      })
    } catch (e) {
      throw e;
    } finally {
      this.loading = false
    }
  }

}