import zhTW from 'antd-mobile/es/locales/zh-TW';


export default {
  ...zhTW,
  label: '繁體中文',
  "home": {
    "search_bar_title": "獲取報告",
    "search_bar_placeholder": "請輸入股票代碼或者簡稱",
    "hot_title": "熱門報告",
    "daily_stock_title": "每日金股",
    "daily_stock_desc": "StockGPT金融大模型推薦",
    "popular_title": "美股人氣榜",
    "popular_desc": "近1個月預測分析報告",
    "holder_title": "大神持倉追蹤",
    "visit_times": '次',
    "up": '看漲',
    "down": '看跌',
  },
  "daily": {
    "stock_title": "每日金股",
    "stock_update_time": "更新",
    "up": '看漲',
    "down": '看跌',
    "predict_text": '市場觀點',
    "stock_declare": "*聲明：診斷數據來源於市場公開信息，經大數據抓取、深度挖掘及AI模型運算整合後得出。所提供信息不構成投資建議，亦不代表公司觀點。 投資有風險，入市需謹慎。"
  },
  "popular": {
    "stock_title": "美股人氣榜",
    "stock_update_time": "更新",
    "visit_times": '次',
    "item_desc": '近1個月預測次數'
  },
  "account": {
    "title": "個人中心",
    "member_expired_time": "會員2024.07.18到期",
    "history": "歷史記錄",
    "up": '看漲',
    "down": '看跌',
    "analyse_report": "分析報告",
    "no_data": "暫無歷史報告",
  },
  "report": {
    "title": "分析報告",
    "time_zone": "美東",
    "comment_btn": "去評價",
    "comment_title": "評價一下",
    "comment_desc": "您對這次的StockGPT股票預測分析報告體驗還滿意嗎？",
    "comment_level_2": "很滿意",
    "comment_level_3": "較差",
    "comment_level_4": "一般",
    "comment_level_5": "還可以",
    "comment_level_6": "很棒",
    "up": '看漲',
    "down": '看跌',
    "close_price": "收盤價",
    "week_extend": "近一週漲跌幅",
    "company": "公司官網",
    "tab_1": "輿情分析",
    "tab_2": "預測分析",
    "tab_3": "技術分析",
    "tab_4": "大神持倉",
    "summary": "`資訊與財報數據截止到${date}，共${count}條資訊`",
    "adjective": "正面因素",
    "negative": "負面因素",
    "predict_analyse": "預測分析",
    "predicting": "預測中",
    "result_day": "結果日",
    "predict_week": "預測未來一週",
    "predict_date": "預測日",
    "technique_analyse": "技術分析",
    "k_line": "基於近3個月的日K線",
    "holder": "大神投資者",
    "look_all": "查看全部",
    "holder_update_time": "更新時間",
    "holder_count": "大神數量",
    "all_holders_percent": "占所有大神總持倉的比例",
    "hold_price": "持有價格",
    "active_type_all": "全部",
    "active_type_add": "增倉",
    "active_type_reduce": "減倉",
    "active_type_buy": "建倉",
    "active_type_sell": "清倉",
    "holder_list_title_1": "股東名稱",
    "holder_list_title_2": "持倉占比",
    "holder_list_title_3": "較上期變化",
    "holder_list_title_4": "持有股數",
    "holder_list_title_5": "持股市值($)",
    "holder_currentValues_format_unit": "萬",
    "indicator_analysis_fields_price": "價格走勢",
    "indicator_analysis_fields_volume": "成交量",
    "indicator_analysis_fields_relationship": "價量關係",
    "indicator_analysis_fields_indicator": "技術指標",
    "indicator_analysis_fields_trend": "總體趨勢及預測",
    "indicator_analysis_fields_summary": "投資建議總結",
    "holder_holdPrice_desc_1": "*「持有價格」是投資者仍持有證券的最後已知價格。它是證券截至投資組合日期（季度末）的收盤價。僅當證券由至少兩名投資者擁有時才會提供此值。如果報告日期不同，則計算加權平均價格。",
    "holder_holdPrice_desc_2": "介紹：\nSEC 要求持有價值 1 億美元或以上證券的機構基金經理在每個季度末提交 13F 表格披露報告。 儘管管理人員可以對某些職位尋求保密處理，但一般規則是職位必須在季度結束後45 天內報告。",
    "news_type": "專欄",
    "news_desc": "過去一週重要資訊",
    "news_no_data": '暫無篩選結果',
    "news_list_more": "加載更多",
    "news_list_no_more": "已經沒有更多了",
    "news_declare": "*聲明：診斷數據來源於市場公開信息，經大數據抓取、深度挖掘及AI模型運算整合後得出。所提供信息不構成投資建議，亦不代表公司觀點。 投資有風險，入市需謹慎。"
  },
  "transition": {
    "task_1": "加載數據",
    "task_2": "導入資訊",
    "task_3": "量化計算",
    "task_4": "輿情分析",
    "task_5": "多維預測",
    "task_6": "報告生成",
    "title": "分析預測",
    "AI_desc": "StockGPT AI模型運行中",
    "done": "的分析報告已完成",
    "look_report": "立即查看", 
    "report_done": "診斷完成",
  },
  "investor": {
    "list_title": "超級投資者",
    "detail_title": "機構詳情",
    "detail_summary": "概括",
    "detail_update_time": "更新時間",
    "detail_during": "期間",
    "detail_invest_time": "投資組合日期",
    "detail_stock_count": "股票數量",
    "detail_invest_value": "投資組合價值",
    "detail_hold_list": "持股列表",
    "detail_active_type_all": "全部",
    "detail_active_type_add": "增倉",
    "detail_active_type_reduce": "減倉",
    "detail_active_type_buy": "建倉",
    "detail_active_type_sell": "清倉",
    "detail_holder_list_title_1": "股東名稱",
    "detail_holder_list_title_2": "持倉占比",
    "detail_holder_list_title_3": "較上期變化",
    "detail_holder_list_title_4": "持有股數",
    "detail_holder_list_title_5": "持股市值($)",
    "detail_currentValues_format_unit": "萬"
  },
  "login": {
    "title": "免費註冊或登入",
    "placeholder_phone": "輸入手機號",
    "placeholder_email": "請輸入郵箱",
    "verify_btn": "獲取驗證碼",
    "or": "或者",
    "guide": "使用協定",
    "privacy": "隱私協定",
    "login_fail": "登入失敗",
    "login": "登入",
    "register": "注册",
    "verify_phone": "驗證碼已發送至",
    "verify_email": "驗證碼已發送至",
    "re_send": "重新發送",
    "google_error": "穀歌授權出錯"
  },
  "bind": {
    "title": "登入成功，請綁定手機號",
    "description": "綁定後下次可直接使用手機號登入",
    "verify_btn": "獲取驗證碼",
    "modal_title": "請確認是否綁定",
    "text1": "此手機號已注册<span>StockGPT</span>帳號，是否將該微訊號與",
    "text2": "手機號綁定？",
    "confirm": "確認綁定",
    "change_phone": "換個手機號",
    "bind_success": "綁定成功",
    "confirm_txt": "已瞭解",
    "contact_text": "您可使用此手機號登入當前<span>StockGPT</span>帳號",
    "bind_fail": "電話綁定失敗",
    "verify_title": "請輸入驗證碼",
    "done": "完成",
    "verify_phone": "驗證碼已發送至+86",
    "re_send": "重新發送",
    "input_phone": "輸入手機號"
  }
}