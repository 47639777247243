import request from "../utils/request";
import qs from "query-string";

export const postOrder = async (data) => {
  return request.post('/orders', {requestType: 'form', data})
}

export const loadOrder = async (key) => {
  return request.get(`/orders/${key}`);
}

export const putStatus = async (key, status) => {
  return request.put(`/orders/${key}`, {requestType: 'form', data: {status}})
}

export const findOrder = async (query) => {
  return request.get(qs.stringifyUrl({
    url: '/orders', query
  }, {skipEmptyString: true, skipNull: true}))
}